import { GET_NOTIFICATION } from '../type';

interface NotificationState {
  notifications: {
    rows: NotificationSystem[];
    pagination: Pagination;
  };
}

const initialState: NotificationState = {
  notifications: {
    rows: [],
    pagination: {
      current: 1,
      nb_page: 0,
      page_limit: 0,
      page_max: 0,
      page_min: 0,
      total: 0
    }
  }
};

export default (state = initialState, action: any): NotificationState => {
  switch (action.type) {
    case GET_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload
      };
    default:
      return state;
  }
};
