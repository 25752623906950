import {
  AUTH,
  ERROR_AUTH,
  CLEAR_ERROR_AUTH,
  GET_AVATAR,
  UPDATE_AVATAR,
  LOGOUT,
  LOAD,
  REGISTER_USER,
  LOADING,
  GET_MENU
} from '../type';

interface AuthState {
  auth: boolean;
  token: string | null;
  error: string | null;
  avatar: string | null;
  success: string | null;
  step: boolean;
  user: any;
  loading: boolean;
  menus: [];
}

const initialState: AuthState = {
  auth: false,
  error: '',
  token: '',
  avatar: null,
  success: '',
  step: false,
  user: {},
  loading: false,
  menus: []
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING: {
      return { ...state, loading: action.payload };
    }
    case AUTH: {
      return {
        ...state,
        token: action.payload
      };
    }
    case GET_MENU: {
      return { ...state, menus: action.payload };
    }
    case ERROR_AUTH: {
      return { ...state, error: action.payload };
    }
    case CLEAR_ERROR_AUTH: {
      return { ...state, error: '' };
    }
    case LOGOUT: {
      return { ...state, token: '', user: {}, avatar: null, menus: [] };
    }
    case REGISTER_USER:
      return {
        ...state,
        success: action.payload.success,
        step: action.payload.step
      };
    case 'CLEAR_SUCCESS':
      return {
        ...state,
        success: '',
        step: false
      };
    case GET_AVATAR:
      return { ...state, avatar: action.payload };
    case LOAD:
      return { ...state, user: action.payload };
    case UPDATE_AVATAR:
      return { ...state, avatar: action.payload };
    default:
      return state;
  }
};
