import { ADD_CART_SHOP, REMOVE_CART_SHOP, EMPTY_CART } from '../type';

interface CartShop {
  cartShop: any[];
}

const initialValue: CartShop = {
  cartShop: []
};

export default (state = initialValue, action: any) => {
  switch (action.type) {
    case ADD_CART_SHOP: {
      if (state.cartShop.includes(action.payload.product_uuid)) {
        return {
          ...state,
          cartShop: state.cartShop.filter((item: any) => item.product_uuid !== action.payload.product_uuid)
        };
      } else {
        return {
          ...state,
          cartShop: [...state.cartShop, action.payload]
        };
      }
    }
    case REMOVE_CART_SHOP: {
      const newCarts = state.cartShop.filter((item: any) => item.product_uuid !== action.payload.product_uuid);
      return {
        ...state,
        cartShop: newCarts
      };
    }
    case EMPTY_CART: {
      return { ...state, cartShop: [] };
    }
    default:
      return state;
  }
};
