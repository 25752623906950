import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import Routes from './routes/index';
import i18n from './i18next';
import './assets/scss/themes.scss';
import 'remixicon/fonts/remixicon.css';
import 'boxicons/css/boxicons.min.css';
import 'line-awesome/dist/font-awesome-line-awesome/css/all.min.css';
import '@mdi/font/css/materialdesignicons.min.css';
import 'react-toastify/dist/ReactToastify.css';

localStorage.setItem('I18N_LANGUAGE', 'en');
i18n.init();
const App = () => {
  document.documentElement.setAttribute('data-layout', 'vertical');
  document.documentElement.setAttribute('data-sidebar-size', 'lg');

  useEffect(() => {
    localStorage.setItem('I18N_LANGUAGE', 'en');
    i18n.init();
  }, []);

  return (
    <React.Fragment>
      <Routes />
      <ToastContainer closeButton={true} position="top-center" theme="colored" bodyClassName="mb-2" />
    </React.Fragment>
  );
};

export default App;
