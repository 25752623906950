import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import LazyLoading from '../components/LazyLoading';
import FourByFour from '../pages/errors/FourByFour';

const Dashboard = lazy(() => import('../pages/dashboard'));
const Layout = lazy(() => import('../layout'));
const Orders = lazy(() => import('../pages/orders'));
const OrderDetail = lazy(() => import('../pages/orders/OrderDetail'));
const Commission = lazy(() => import('../pages/commision'));
const Configuration = lazy(() => import('../pages/config'));
const Chats = lazy(() => import('../pages/chats'));
const MyProduct = lazy(() => import('../pages/products'));
const Shop = lazy(() => import('../pages/shop'));
const UkuajiProductDetail = lazy(() => import('../components/products/UkuajiProductDetail'));
const Withdraw = lazy(() => import('../pages/withdraw'));
const VerifyUser = lazy(() => import('../pages/auth/VerifyUser'));

// lazy loading
const AuthPage = lazy(() => import('../pages/auth/AuthUkuaji'));
const ForgetPassword = lazy(() => import('../pages/auth/ForgotPassword'));

const Index: React.FC = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<LazyLoading />}>
              <AuthPage />
            </React.Suspense>
          }
        />
        <Route
          path="forgot-password"
          element={
            <React.Suspense fallback={<LazyLoading />}>
              <ForgetPassword />
            </React.Suspense>
          }
        />
        <Route path="login" element={<Navigate to="/" />} />
        <Route element={<PrivateRoute />}>
          <Route
            path="account"
            element={
              <React.Suspense fallback={<LazyLoading />}>
                <Layout />
              </React.Suspense>
            }
          >
            <Route
              index
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <Dashboard />
                </React.Suspense>
              }
            />
            <Route
              path="shop"
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <Shop />
                </React.Suspense>
              }
            />
            <Route
              path="shop/:productUuid"
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <UkuajiProductDetail />
                </React.Suspense>
              }
            />
            <Route
              path="shop/list/:productUuid"
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <UkuajiProductDetail />
                </React.Suspense>
              }
            />
            <Route
              path="discussions"
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <Chats />
                </React.Suspense>
              }
            />
            <Route
              path="orders"
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <Orders />
                </React.Suspense>
              }
            />
            <Route
              path="orders/:uuid"
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <OrderDetail />
                </React.Suspense>
              }
            />
            <Route
              path="commission"
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <Commission />
                </React.Suspense>
              }
            />
            <Route
              path="commission/:uuid"
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <OrderDetail isEarn={true} />
                </React.Suspense>
              }
            />
            <Route
              path="configuration"
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <Configuration />
                </React.Suspense>
              }
            />
            <Route
              path="products"
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <MyProduct />
                </React.Suspense>
              }
            />
            <Route
              path="products/:productUuid"
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <UkuajiProductDetail />
                </React.Suspense>
              }
            />
            <Route
              path="products/list/:productUuid"
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <UkuajiProductDetail />
                </React.Suspense>
              }
            />
            <Route
              path="withdrawal"
              element={
                <React.Suspense fallback={<LazyLoading />}>
                  <Withdraw />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
        {/* verify email */}
        <Route
          path="verify-email/:uuid"
          element={
            <React.Suspense fallback={<LazyLoading />}>
              <VerifyUser />
            </React.Suspense>
          }
        />
        <Route path="*" element={<FourByFour />} />
      </Routes>
    </React.Fragment>
  );
};

export default Index;
