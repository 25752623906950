import { applyMiddleware, compose, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import authReducer from './reducer/auth.reducer';
import layoutReducer from './reducer/layout.reducer';
import configReducer from './reducer/config.reducer';
import myProductReducer from './reducer/myproduct.reducer';
import cartReducer from './reducer/cart.reducer';
import cartShopReducer from './reducer/cartShop.reducer';
import wishListReducer from './reducer/wishList.reducer';
import customerReducer from './reducer/customer.reducer';
import filterReducer from './reducer/filter.reducer';
import statReducer from './reducer/stat.reducer';
import userReducer from './reducer/user.reducer';
import notificationReducer from './reducer/notification.reducer';

declare global {
  interface Window {
    REDUX_DEVTOOLS_EXTENSION_COMPOSE?: typeof compose;
  }
}

const composeEnhancers: any = (typeof window !== 'undefined' && window.REDUX_DEVTOOLS_EXTENSION_COMPOSE != null) || compose;

const persistRootConfig = {
  key: 'root',
  storage
};

const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  config: configReducer,
  myProduct: myProductReducer,
  cartProduct: cartReducer,
  wish: wishListReducer,
  customer: customerReducer,
  filter: filterReducer,
  cartStore: cartShopReducer,
  statStore: statReducer,
  user: userReducer,
  notification: notificationReducer
});

const persistRootReducer = persistReducer(persistRootConfig, rootReducer);
export type AppState = ReturnType<typeof rootReducer>;
export const store = createStore(persistRootReducer, composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store);
