import { ADD_PRODUCT, ERROR_PRODUCT, CLEAR_ERROR, HYDRATE_PRODUCT, LOGOUT, LOAD_ALL_PRODUCT, LOAD_PRODUCT } from '../type';

// reducer

interface MyProductState {
  products: {
    rows: any[];
    pagination: Pagination;
  };
  productSelected: any[];
  allProduct: {
    rows: any[];
    pagination: Pagination;
  };
  category: [];
  errorProduct: string | null;
  successProduct: string | null;
}

const initialState: MyProductState = {
  products: {
    rows: [],
    pagination: {
      current: 1,
      nb_page: 0,
      page_limit: 0,
      page_max: 0,
      page_min: 0,
      total: 0
    }
  },
  allProduct: {
    rows: [],
    pagination: {
      current: 1,
      nb_page: 0,
      page_limit: 0,
      page_max: 0,
      page_min: 0,
      total: 0
    }
  },
  productSelected: [],
  category: [],
  errorProduct: '',
  successProduct: ''
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOAD_ALL_PRODUCT:
      return { ...state, allProduct: action.payload };
    case LOAD_PRODUCT:
      return { ...state, products: action.payload };
    case ADD_PRODUCT:
      if (state.productSelected.includes(action.payload)) {
        return {
          ...state,
          productSelected: state.productSelected.filter((id: any) => id !== action.payload)
        };
      } else {
        return {
          ...state,
          productSelected: [...state.productSelected, action.payload]
        };
      }
    case HYDRATE_PRODUCT:
      return {
        ...state,
        productSelected: []
      };
    case ERROR_PRODUCT:
      return {
        ...state,
        errorProduct: action.payload
      };
    case CLEAR_ERROR:
      return {
        ...state,
        errorProduct: null
      };
    case LOGOUT:
      return {
        ...state,
        productSelected: [],
        products: { rows: [], pagination: {} },
        category: [],
        allProduct: { rows: [], pagination: {} }
      };
    default:
      return state;
  }
};
