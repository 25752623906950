import { connect } from 'socket.io-client';
import { createContext } from 'react';

export const socket = connect(`${process.env.REACT_APP_API}`, {
  transports: ['websocket'],
  upgrade: false,
  query: {
    token: localStorage.getItem('jwt'),
    uuid: JSON.parse(localStorage.getItem('user')!)?.uuid
  }
});
export const SocketContext = createContext(socket);
