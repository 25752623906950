import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import axios from 'axios';
import { HelmetProvider } from 'react-helmet-async';
import { store, persistor } from './store/store';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './pages/errors/ErrorBundary';
import { SocketContext, socket } from './context/socket';

axios.defaults.baseURL = `${process.env.REACT_APP_API}`;
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;

const Index = () => {
  return (
    <MantineProvider>
      <NotificationsProvider position="top-left">
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </NotificationsProvider>
    </MantineProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('ukuaji') as HTMLElement);
root.render(
  <ErrorBoundary>
    <SocketContext.Provider value={socket}>
      <HelmetProvider>
        <Index />
      </HelmetProvider>
    </SocketContext.Provider>
  </ErrorBoundary>
);

serviceWorkerRegistration.register();

reportWebVitals();
