import { GET_USER } from '../type';

interface UserState {
  user: Users;
}

const initialState: UserState = {
  user: {
    uuid: '',
    firstname: '',
    lastname: '',
    middlename: '',
    phone: '',
    amount: 0,
    is_active: 0,
    avatar: null,
    email: ''
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_USER: {
      return { ...state, user: action.payload };
    }
    default:
      return state;
  }
};
