import React from 'react';
import { Spinner } from 'reactstrap';

const LazyLoading: React.FC = () => {
  return (
    <div className="text-center mt-5">
      <Spinner type="border" color="primary" className="mt-5">
        {' '}
        {/* Loading{' '} */}
      </Spinner>
    </div>
  );
};

export default LazyLoading;
