/**
 * @File Show the message if crash app , possibility the refresh the app
 * @author Ebed meleck
 */

import React, { Component, ErrorInfo } from 'react';
import errorOccurred from '../../assets/lottie/error-occurred.json';
import Lottie from 'lottie-react';

/**
 * @category Screen crash app
 */

const styles: React.CSSProperties = {
  textAlign: 'center',
  marginTop: 50,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 300,
  height: 300,
  margin: 'auto',
  padding: 15
};

const btn: React.CSSProperties = {
  marginTop: 25,
  width: 140,
  height: 40,
  objectFit: 'contain'
};

interface Props {
  children: JSX.Element;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /** We have the possibility to record its errors and then correct it and display a rescue interface
   * componentDidCatch(error, info) {
   *  send error to server
   * }
   */
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error  sorry');
    console.warn(error.message);
  }

  restart() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="text-center">
          <div style={styles}>
            <Lottie animationData={errorOccurred} loop width={300} height={280} />
            <h4 className="mt-4">Oups sorry ! something ⛄ </h4>
            <button type="button" className="btn btn-danger mt-5 w-75" style={{ marginTop: 40 }} onClick={() => this.restart()}>
              Restart
            </button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
