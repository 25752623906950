import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import Error404 from '../../assets/lottie/404-not-found.json';

const FourByFour = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="auth-page-content">
        <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
          <div className="auth-page-content overflow-hidden p-0  me-4">
            <Container>
              <Row className="justify-content-center me-4">
                <Col xl={7} lg={8} className="me-2">
                  <div className="text-center">
                    <Lottie
                      className="img-thumbnail d-block"
                      animationData={Error404}
                      loop={true}
                      style={{
                        objectFit: 'contain'
                      }}
                    />
                    <div className="">
                      <h3 className="text-uppercase">{t('NOT.404')} 😭</h3>
                      <p className="text-muted mb-4">{t('NOT.PAGE')}</p>
                      <Link to="/" className="btn btn-primary">
                        <i className="ri-home-5-line me-1"></i>
                        {t('BACK_HOME')}
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FourByFour;
