import { GET_STAT } from '../type';

interface StatDash {
  nbre: number;
}

const initialState: StatDash = {
  nbre: 0
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_STAT:
      return { ...state, nbre: action.payload };
    default:
      return state;
  }
};
