import { TOGGLE_WISH_LIST } from '../type';

interface WishType {
  wishList: any[];
}

const initialState: WishType = {
  wishList: []
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_WISH_LIST: {
      const wishIndex = state.wishList.findIndex((item: any) => item.product_uuid === action.payload.product_uuid);
      if (wishIndex !== -1) {
        // remove product in the list
        return {
          ...state,
          wishList: state.wishList.filter((item: any, index: number) => index !== wishIndex)
        };
      } else {
        return {
          ...state,
          wishList: [...state.wishList, action.payload]
        };
      }
    }
    default:
      return state;
  }
};
