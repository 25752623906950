import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import fr from './locales/fr.json';
import sw from './locales/sw.json';

const resources = {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  },
  sw: {
    translation: sw
  }
};

const languages = localStorage.getItem('I18N_LANGUAGE');

if (languages != null) {
  localStorage.setItem('I18N_LANGUAGE', 'en');
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    load: 'languageOnly',
    interpolation: {
      escapeValue: false
    },
    lng: 'en'
  });

export default i18n;
