import { SEARCH_PRODUCT, CLEAR_SEARCH_PRODUCT, ADD_SORT, CLEAR_SORT } from '../type';

interface FilterRedux {
  product: any[];
  sort: {
    name: string;
    priceMin: string | number;
    priceMax: string | number;
    color: string;
    brand: string;
    filtered: boolean;
  };
}

const initialState: FilterRedux = {
  product: [],
  sort: {
    name: '',
    priceMin: '',
    priceMax: '',
    color: '',
    brand: '',
    filtered: false
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SEARCH_PRODUCT:
      return {
        ...state,
        product: action.payload
      };
    case CLEAR_SEARCH_PRODUCT:
      return { ...state, product: [] };
    case ADD_SORT:
      return {
        ...state,
        sort: action.payload
      };
    case CLEAR_SORT:
      return {
        ...state,
        sort: {
          category: '',
          priceMin: '',
          priceMax: '',
          size: '',
          color: '',
          brand: '',
          filtered: false
        }
      };
    default:
      return state;
  }
};
