export const AUTH = 'AUTH';
export const ERROR_AUTH = 'ERROR_AUTH';
export const GET_MENU = 'GET_MENU';
export const REGISTER_USER = 'REGISTER_USER';
export const CLEAR_ERROR_AUTH = 'CLEAR_ERROR_AUTH';
export const LOGOUT = 'LOGOUT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const GET_AVATAR = 'GET_AVATAR';
export const LOAD = 'LOAD';
export const RESET_PASSWORD_CONFIRM = 'RESET_PASSWORD_CONFIRM';
export const LOADING = 'LOADING';
export const ERROR_APP = 'ERROR_APP';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CHANGE_MODE = 'CHANGE_MODE';
export const GET_CONFIG = 'GET_CONFIG';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const MODIFY_PRODUCT = 'MODIFY_PRODUCT';
export const GET_PRODUCT = 'GET_PRODUCT';
export const ERROR_PRODUCT = 'ERROR_PRODUCT';
export const HYDRATE_PRODUCT = 'HYDRATE_PRODUCT';
export const GET_PERCENTAGE = 'GET_PERCENTAGE';
export const GET_CONFIG_STORE = 'GET_CONFIG_STORE';
export const ADD_CART = 'ADD_CART';
export const REMOVE_CART = 'REMOVE_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const TOGGLE_WISH_LIST = 'TOGGLE_WISH_LIST';
export const REMOVE_WISH_LIST = 'REMOVE_WISH_LIST';
export const REGISTER_CUSTOMER = 'REGISTER_CUSTMER';
export const AUTH_CUSTOMER = 'AUTH_CUSTOMER';
export const ERROR_CUSTOMER = 'ERROR_CUSTOMER';
export const CLEAR_ERROR_CUSTOMER = 'CLEAR_ERROR_CUSTOMER';
export const LOGOUT_CUSTOMER = 'LOGOUT_CUSTOMER';
export const GET_STORE_PUBLIC = 'GET_STORE_PUBLIC';
export const TOGGLE_OPEN = 'TOGGLE_OPEN';
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_SEARCH_PRODUCT = 'CLEAR_SEARCH_PRODUCT';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_BRANDS = 'GET_BRANDS';
export const ADD_SORT = 'ADD_SORT';
export const CLEAR_SORT = 'CLEAR_SORT';
export const RELOAD_CUSTOMER = 'RELOAD_CUSTOMER';
export const ADD_CART_SHOP = 'ADD_CART_SHOP';
export const REMOVE_CART_SHOP = 'REMOVE_CART_SHOP';
export const EMPTY_CART = 'EMPTY_CART';
export const GET_CART = 'GET_CART';
export const GET_STAT = 'GET_STAT';
export const SAVE_CART = 'SAVE_CART';
export const GET_USER = 'GET_USER';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const LOAD_ALL_PRODUCT = 'LOAD_ALL_PRODUCT';
export const LOAD_PRODUCT = 'LOAD_PRODUCT';
